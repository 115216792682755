import onroORG from "./onroORG";
import Fab from "./Fab";
import loco from "./loco";
import cartingo from "./cartingo";
import pickup from "./pickup";
import onro from "./onro";
import acistant from "./acistant";
import asankar from "./asankar";
import deliro from "./deliro";
import doorpost from "./doorpost";
import YONI from "./YONI";
import emrazgo from "./emrazgo";
import hellocourier from "./hellocourier";
import vee from "./vee";
import oto from "./oto";
import HiO from "./HiO";
import Geenstress from "./Geenstress";
import TEZRAFTAAR from "./TEZRAFTAAR";
import PickGo from "./PickGo";
import wadelivery from "./wadelivery";
import Zoomcurier from "./zoomcurier";
import Swiftee from "./Swiftee";
import Sanic from "./Sanic";
import QuickSend from "./QuickSend";
import Munchi from "./Munchi";
import BESwifty from "./BESwifty";
import VroomVroom from "./VroomVroom";
import ASKERI from "./ASKERI";
import DLX from "./DLX";
import DMO from "./DMO";
import STRIKEAFRICA from "./STRIKEAFRICA";
import Superkiwi from "./Superkiwi";
import Annanow_pro from "./Annanow (Production)";
import Annanow_stage from "./Annanow (Stage)";
import WeeDeliver from "./Wee Deliver";
import Kwikdash from "./Kwikdash";
import Cityspider from "./Cityspider";
import JRUN from "./J-RUN";
import Sprint from "./Sprint";
import Flech from "./Flech";
import AOffer from "./AOffer";
import CaseWorld from "./Case World";
import CarRecovery from "./CarRecovery";
import latreqi from "./3latreqi";
import Rappid from "./Rappid";
import GetItPicked from "./Get It Picked";
import Floti from "./floti";
import FAM from "./FAM";
import SwiftitExpress from "./SwiftitExpress";
import Automater from "./Automater";
import KerryExpress from "./Kerry Express";
import NWACourier from "./NWA";
import Flyto from "./Flyto";
import KerryExpress_Instance from "./Kerry Express Instance";
import Bristol from "./Bristol";
import ClaraBiofuels from "./Clara Biofuels";
import Scooty from "./scooty";
import NOBODI from "./NOBODI";
import Gripx from "./Gripx";
import BIG_TIC_IT from "./BIG TIC-IT";
import couriers24 from "./couriers24";
import JenaTrucks from "./JenaTrucks";
import Geshikt from "./Geshikt";
import SkiptheQ from "./SkiptheQ";
import Thecryogroup from "./thecryogroup";
import Biocair from "./Biocair";
import ParcelBeep from "./Parcel Beep";
import Maydaycourier from "./maydaycourier";
import PinkExpress from "./pinkExpress";
import Reflexcourier from "./reflexcourier";
import Snapbox from "./Snapbox";
import Anypoint from "./Anypoint";
import Thetruckingforme from "./thetruckingforme";
import Privitrides from "./privitrides";
import BeyondDelivery from "./BeyondDelivery";
import Mannaev from "./mannaev";
import landeber from "./landeber";
import Joradelivery from "./joradelivery";
import Gopanda from "./gopanda";
import onroORG2 from "./onroORG2";
import CouriersExpress from "./CouriersExpress";
import Swishr from "./swishr";
import Kiosken from "./kiosken";
import Fasttrack from "./Fasttrack";
import Ondelivery from "./ondelivery";
import Kwikr from "./Kwikr";
import LoadmanExpress from "./loadmanexpress";
import EaglePost from "./EaglePost";
import Solutions1000 from "./Solutions1000";
import PickNDrop from "./PickNDrop";
import EzDelivery from "./EzDelivery";
import Getty from "./Getty";
import Deliveryondemand from "./Deliveryondemand";


export const setGlobalSettings = () => {
    switch (window.location.hostname) {
        case "dispatcher.fab.com.np": return Fab();
        case "dispatcher.loco.direct": return loco();
        case "dispatcher.catrin-go.com": return cartingo();
        case "dispatcher.pickup.global": return pickup();
        case "dispatcher.onro.io": return onro();
        case "dispatcher.onro.org": return onroORG();
        case "2dispatcher.onro.org": return onroORG2();
        case "dispach.acistant.xyz": return acistant();
        case "dispatcher.asankar.co": return asankar();
        case "dispatcher.deliro.ir": return deliro();
        case "dispatcher.doorpost.com.au": return doorpost();
        case "dispatcher.yoni-app.sn": return YONI();
        case "dispatcher.emrazgo.com": return emrazgo();
        case "dispatcher.hellocourier.co.ke": return hellocourier();
        case "dispatcher.veedelivery.com": return vee();
        case "dispatcher.otokh.com": return oto();
        case "localhost": return onroORG() ;
        // case "localhost": return onro();
        case "dispatcher.gomunchi.com": return Munchi();
        case "dispatcher.hio.ro": return HiO();
        case "dispatcher.geenstress.amsterdam": return Geenstress();
        case "dispatcher.trcs.pk": return TEZRAFTAAR();
        case "dispatcher.pickngosg.com": return PickGo();
        case "dispatcher.wadelivery.com": return wadelivery();
        case "dispatcher.curier-local.ro": return Zoomcurier(); break;
        case "dispatcher.swiftee.co.uk": return Swiftee(); break;
        case "dispatcher.saniclogistics.com": return Sanic(); break;
        case "dispatcher.quicksend.ug": return QuickSend(); break;
        case "dispatcher.gomunchi.com": return Munchi();
        case "dispatcher.beswifty.co.uk": return BESwifty();
        case "dispatcher.vroomvroom.ph": return VroomVroom();
        case "operations.askeriglobal.com": return ASKERI();
        case "dispatcher.dlxapp.online": return DLX();
        case "dispatcher.dmoapp.in": return DMO();
        case "dispatcher.strikeapp.link": return STRIKEAFRICA();
        case "dispatcher.sklogistics.do": return Superkiwi();
        case "dispatcher.stage.anna-now.com": return Annanow_stage();
        case "dispatcher.anna-now.com": return Annanow_pro();
        case "manage.weedeliver.live": return WeeDeliver();
        case "dispatcher.kwikdash.co": return Kwikdash();
        case "dispatcher.rybit-cityspider.com": return Cityspider();
        case "dispatcher.j-run.az": return JRUN();
        case "oddispatcher.sprint.xyz": return Sprint();
        case "dispatcher.flech.io": return Flech();
        case "dispatcher.aofferonline.com": return AOffer();
        case "dispatcher.caseworldweb.com": return CaseWorld();
        case "dispatcher.aladden.org": return CarRecovery();
        case "dispatcher.3latreqi.com": return latreqi();
        case "dispatcher.tryrappid.com": return Rappid();
        case "dispatch.getitpicked.com": return GetItPicked();
        case "monitor.floti.io": return Floti();
        case "dispatcher.famvalet.com": return FAM();
        case "dispatcherbackoffice.swiftitexpress.com": return SwiftitExpress();
        case "dispatcher.autowmater.com": return Automater();
        case "dispatcher.kerryexpress.ph": return KerryExpress();
        case "dispatch.nwacourier.com": return NWACourier();
        case "dispatcher.flyto.fi": return Flyto();
        case "dispatcher.automoto.ph": return KerryExpress_Instance();
        case "dispatcher.bristolcountycouriers.com": return Bristol();
        case "dispatcher.clarabiofuels.com": return ClaraBiofuels();
        case "dispatcher.scooty.gr": return Scooty();
        case "manage.nobodi.com": return NOBODI();
        case "dispatcher.gripx.ph": return Gripx();
        case "dispatcher.bigtic-it.com": return BIG_TIC_IT();
        case "dispatcher.24couriers.co.uk": return couriers24();
        case "dispatcher.jenatrucks.com": return JenaTrucks();
        case "dispatcher.geshikt.be": return Geshikt();
        case "dispatcher.skiptheq.com": return SkiptheQ();
        case "dispatcher.thecryogroup.com.au": return Thecryogroup();
        case "dispatcher.partner.biocair.com": return Biocair();
        case "dispatcher.parcelbeep.com": return ParcelBeep();
        case "dispatcher.maydaycourier.com": return Maydaycourier();
        case "dispatcher.pink.express": return PinkExpress();
        case "dispatcher.reflexcourier.ca": return Reflexcourier();
        case "dispatcher.snapboxjo.com": return Snapbox();
        case "dispatcher.anypoint.live": return Anypoint();
        case "dispatcher.thetruckingforme.com": return Thetruckingforme();
        case "dispatcher.privitrides.com": return Privitrides();
        case "dispatcher.beyond-delivery.com": return BeyondDelivery();
        case "rider.mannaev.com": return Mannaev();
        case "dispatcher.landeber.com": return landeber();
        case "dispatcher.joradelivery.com": return Joradelivery();
        case "dispatcher.gopanda.uk": return Gopanda();
        case "dispatcher.couriersexpress.com": return CouriersExpress();
        case "manage63842.swishrcourier.com": return Swishr();
        case "dispatcher.kiosken.site": return Kiosken();
        case "dispatcher.fastrack.lu": return Fasttrack();
        case "dispatcher.ondelivery.us": return Ondelivery();
        case "manage.kwikr.com": return Kwikr();
        case "manage.loadmanexpress.com": return LoadmanExpress();
        case "manage.eaglepost.com": return EaglePost();
        case "manage.1000solutions.ae": return Solutions1000();
        case "manage.pickndrop.io": return PickNDrop();
        case "manage.ezdeliveryservices.com": return EzDelivery();
        case "manage.gettico.com": return Getty();
        case "manage.deliveryondemand.co": return Deliveryondemand();

        default: return onro();
    }
};

setGlobalSettings()