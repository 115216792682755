import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../assets/chevron-down.svg";
import trash from "../../../../../assets/ic-trash.svg";
import styles from './dropoff.module.css';

import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import {
  Add_dropoff,
  Edit_Travel_set_Dropoff_inputs,
  Set_Pickup_address,
  Set_SearchAutoCompleteLoading,
  Set_latlng,
  Edit_Travel_set_Dropoff,
  Edit_Travel_Operate_Changed,
  OpenNewFav,
  SetopenAddressBookInNewOrder,
} from "../../../../../Redux/actions/actionNames";
import {
  SearchAutoCompleteAction,
  SearchAddressAction,
  PlaceIdDetailsAction,
  getFavAddressInfoAction,
  SearchAutoCompleteAction_local_address,
} from "../../../../../Redux/actions/actions";
import closeCircle from "../../../../../assets/close-cricle.svg";
import savedarrow from "../../../../../assets/icon-arrow-triangle-up.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CircularProgress from "@material-ui/core/CircularProgress";
import Schedule from "./Schedule";
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../../helper/UserData/userdate";
import _, { debounce, isEmpty, throttle } from "lodash";
import PhoneInput from "react-phone-number-input";
import { getCenterMap, getCountryWithDomain, setCenterMap } from "../../../../../helper/module";
import { useLocalServices } from "../../../../../hooks";
import drag_drop from "../../../../../assets/drag_drop.svg";
import { v4 as uuidv4 } from 'uuid';
import address_database from "../../../../../assets/address_database.webp";
import MapSearch from "../../../../../assets/MapSearch.webp";
import iconicperson from "../../../../../assets/icon-ic-person.svg";
import icClock from "../../../../../assets/ic-clock.svg";
import iconIcPin from "../../../../../assets/icon-ic-pin.svg";
import icclockcolorfull from "../../../../../assets/ic-clock-colorfull.svg";
import Typography from "../../../../../design-system/lib/Typography";
import Button from "../../../../../design-system/lib/Button";
import SearchList from "../../../../../design-system/lib/SearchList";
import IconProvider from "../../../../../design-system/lib/IconProvider";
import { ADDRESS_BOOK, CLOSE_DARK, DROPOFF, HISTORY, INFO_CIRCLE, LOCATION, PICKUP } from "../../../../../assets";
import DropDown from "../../../../../design-system/lib/DropDown";
import RecentAddressItem from "../../../../../components/new-order/pickup/components/recentAddressItem";
import AddressItem from "../../../../../components/shared/AddressItem";
import DropoffInfoEditOrder from "./dropOff/info";


const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      loading: false,
      ordered: true,
      showpoper: false,
      showpoperSaved: false,
      getFavAddressLoading: false,
      searchAddrees: "",
      list: [],
      cursor:null,
      sessionToken: null ,
      isLocalAddressActive:
      global.config.dispatcherSettings.isLocalAddressActive,
    localSearchIsActive: global.config.dispatcherSettings.isLocalAddressActive
      ? true
      : false,
    };
    this.anchorEl = React.createRef();


    this.throttleHandleChange = debounce(
      this.throttleHandleChange.bind(this),
      800
    );
  }

  
  componentDidUpdate() {
    this._setCursorPositions();
  }

  _setCursorPositions = () => {
    //reset the cursor position for input
    if(this.anchorEl.current && this.state.cursor){
       this.anchorEl.current.selectionStart = this.state.cursor;
       this.anchorEl.current.selectionEnd = this.state.cursor;
    }

  };



  handleChangeLocalSearch = () => {
    this.setState(
      {
        localSearchIsActive: !this.state.localSearchIsActive,
      },
      () => {
        this.notThrottleHandleChange(String(this.props.address));
      }
    );
  };

  notThrottleHandleChange(value) {
    let center = getCenterMap(this.props.mapRef, this.props.center);

    if (!this.state.localSearchIsActive) {
      this.props.dispatch(
        SearchAutoCompleteAction(this.props.dispatch, {
          query: value,
          latitude: center.lat,
          longitude: center.lng,
          sessionToken: this.state.sessionToken,
        })
      );
    } else {
      this.props.dispatch(
        SearchAutoCompleteAction_local_address(this.props.dispatch, {
          query: value,
        })
      );
    }
  }

  throttleHandleChange(value) {
    let center = getCenterMap(this.props.mapRef, this.props.center);

    if (!this.state.localSearchIsActive) {
      this.props.dispatch(
        SearchAutoCompleteAction(this.props.dispatch, {
          query: value,
          latitude: center.lat,
          longitude: center.lng,
          sessionToken: this.state.sessionToken,
        })
      );
    } else {
      this.props.dispatch(
        SearchAutoCompleteAction_local_address(this.props.dispatch, {
          query: value,
        })
      );
    }
  }

  handleshowpoper = () => {
    this.setState({
      showpoper: !this.state.showpoper,
      searchAddrees: "",
      showpoperSaved: false,
    });
  };

  handleshowpoperDrop = () => {
    if (this.props.customerId) {
      this.setState({
        showpoperSaved: !this.state.showpoperSaved,
        searchAddrees: "",
        showpoper: false,
      });
    }
  };
  handleSavedDropInput = (e) => {
    this.setState({
      searchAddrees: e.target.value,
    });
  };

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  handleCollapse = () => {
    this.props.handleCollapse({
      type: "dropoff",
      id: this.state.id,
      firstDropOff: this.props.first
    });
  };


  handlePhoneInput = (value, id) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());
    this.props.dispatch(
      Edit_Travel_set_Dropoff_inputs({
        name: id,
        value: value,
        firstDropOff: this.props.first,
        id: this.state.id,
      })
    );
  };



  handleDropOffInputs = (e, name, value, id) => {
    try {
      
    this.props.dispatch(Edit_Travel_Operate_Changed());

    if (((id ? id : e.target.id) === "address") || name === "address") {

      this.setState({
        sessionToken: this.state.sessionToken ? this.state.sessionToken : uuidv4(),
        cursor : e.target.selectionStart

      })


      // this.props.dispatch(
      //   Edit_Travel_set_Dropoff_inputs({
      //     name: "adresss_loc",
      //     value: { lng: "", lat: "" },
      //     firstDropOff: this.props.first,
      //     id: this.state.id,
      //   })
      // );
      // this.props.dispatch(
      //   Edit_Travel_set_Dropoff_inputs({
      //     name: "placeId",
      //     value: "",
      //     firstDropOff: this.props.first,
      //     id: this.state.id,
      //   })
      // );
      if (value ? value : e.target.value) {
        this.props.dispatch(Set_SearchAutoCompleteLoading(true));

      }
      if (value || e.target.value != "") {
        // this.props.dispatch(
        //   SearchAutoCompleteAction(this.props.dispatch, {
        //     query: e.target.value,
        //     latitude: this.props.mapRef.current.leafletElement.getCenter().lat,
        //     longitude: this.props.mapRef.current.leafletElement.getCenter().lng,
        //   })
        // );
        (value ? value : e.target.value)  && this.throttleHandleChange(value ? value : e.target.value, this.state.sessionToken)
      }
      // this.setState({
      //   showpoper: true
      // });
      // setTimeout(() => {
      //   this.anchorEl.current.selectionEnd = cursor;
      //   this.anchorEl.current.selectionStart = cursor;

      // }, 100);
    }
    this.props.dispatch(
      Edit_Travel_set_Dropoff_inputs({
        name: id || name || e.target.id,
        value: value ? value : e.target.value,
        firstDropOff: this.props.first,
        id: this.state.id,
      })
    );

    } catch (error) {
      
    }

  };

  handleList = (item) => {
    if (item.coordinates) {
      this.props.dispatch(Edit_Travel_Operate_Changed());

      this.props.dispatch(
        Edit_Travel_set_Dropoff_inputs({
          name: "address",
          value: item.secondaryAddress ? item.address + " , " + item.secondaryAddress : item.address,
          firstDropOff: this.props.first,
          id: this.state.id,
        })
      );
      this.props.dispatch(
        Edit_Travel_set_Dropoff_inputs({
          name: "adresss_loc",
          value: {
            lng: item.coordinates[0],
            lat: item.coordinates[1],
          },
          firstDropOff: this.props.first,
          id: this.state.id,
        })
      );

      this.props.dispatch(
        Set_latlng({
          lng: item.coordinates[0],
          lat: item.coordinates[1],
        })
      );

      this.setState({
        sessionToken: null
      })

    } else {

      this.setState({
        sessionToken: this.state.sessionToken ? this.state.sessionToken : uuidv4()
      }, () => {
        this.props.dispatch(
          PlaceIdDetailsAction(this.props.dispatch, { placeId: item.placeId, sessionToken: this.state.sessionToken }, (res) => {
            this.props.dispatch(Edit_Travel_Operate_Changed());
            this.setState({
              sessionToken: null
            })

            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "address",
                value: res.address,
                firstDropOff: this.props.first,
                id: this.state.id,
              })
            );

            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "adresss_loc",
                value: {
                  lng: res.coordinates[0],
                  lat: res.coordinates[1],
                },
                firstDropOff: this.props.first,
                id: this.state.id,
              })
            );

            this.props.dispatch(
              Set_latlng({
                lng: res.coordinates[0],
                lat: res.coordinates[1],
              })
            );
          })
        );
      })


    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.firstDropOff ? "dropoff" : nextProps.id,
    });
  }

  resetPickup = () => {
    this.props.dispatch(Edit_Travel_Operate_Changed());

    this.props.dispatch(
      Edit_Travel_set_Dropoff_inputs({
        name: "address",
        value: "",
        firstDropOff: this.props.first,
        id: this.state.id,
      })
    );
    this.props.dispatch(
      Edit_Travel_set_Dropoff_inputs({
        name: "adresss_loc",
        value: { lng: "", lat: "" },
        firstDropOff: this.props.first,
        id: this.state.id,
      })
    );
    this.props.dispatch(
      Edit_Travel_set_Dropoff_inputs({
        name: "placeId",
        value: "",
        firstDropOff: this.props.first,
        id: this.state.id,
      })
    );
  };

  validate(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  
  removeDropOff = () => {
    this.props.dispatch(Edit_Travel_Operate_Changed());

    this.props.removeDrop(this.state.id);
  };

  hanldeOpenNewFav = () => {
    this.props.dispatch(OpenNewFav(true));
  };

  mapSetView = (e) => {
    setCenterMap(this.props.mapRef, e)
  };

  handleListFavAddress = (e) => {
    this.resetPickup();

    this.props.dispatch(Edit_Travel_Operate_Changed());

    this.setState({
      showpoperSaved: false,
      getFavAddressLoading: true,
    });
    // address: "Somewhere"
    // customerId: "ckfezc72o01f1xxo81la4bvl9"
    // id: "ckh3mcgda0d847bo87td8fk1v"
    // title: "HomeTown"
    if (this.props.customerId) {
      this.props.dispatch(
        getFavAddressInfoAction(
          this.props.dispatch,
          { customerId: e.customerId, id: e.id },
          (res) => {
            this.setState({
              getFavAddressLoading: false,
            });
            this.mapSetView({ lng: res.coordinates[0], lat: res.coordinates[1] });

            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "address",
                value: res.address,
                firstDropOff: this.props.first,
                id: this.state.id,
              })
            );
            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "adresss_loc",
                value: { lng: res.coordinates[0], lat: res.coordinates[1] },
                firstDropOff: this.props.first,
                id: this.state.id,
              })
            );
            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "block",
                value: res.buildingBlock,
                firstDropOff: this.props.first,
                id: this.state.id,
              })
            );
            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "floor",
                value: res.floor,
                firstDropOff: this.props.first,
                id: this.state.id,
              })
            );

            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "unit",
                value: res.room,
                firstDropOff: this.props.first,
                id: this.state.id,
              })
            );
            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "senderFullName",
                value: res.fullName,
                firstDropOff: this.props.first,
                id: this.state.id,
              })
            );
            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "senderPhoneNumber",
                value: res.phone,
                firstDropOff: this.props.first,
                id: this.state.id,
              })
            );
            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "noteForDriver",
                value: res.customerDescription,
                firstDropOff: this.props.first,
                id: this.state.id,
              })
            );
          }
        )
      );
    }

  };


  handleRecentList = (item) => {

    // this.props.dispatch(
    //   Set_latlng({ lng: item.coordinates[0], lat: item.coordinates[1] })
    // );

    // dispatch(
    //   Set_Pickup_address({
    //     ...item,
    //     address: item.address,
    //     adresss_loc: { lng: item.coordinates[0], lat: item.coordinates[1] },
    //     block: item.buildingBlock,
    //     floor: item.floor,
    //     unit: item.room
    //   })
    // );
    // mapSetView({
    //   lng: item.coordinates[0],
    //   lat: item.coordinates[1],
    // });

    // handleShowInfo()


    this.props.dispatch(Edit_Travel_Operate_Changed());

    this.setState({
     sessionToken: null,
   });

   this.props.dispatch(
    Edit_Travel_set_Dropoff_inputs({
      name: "address",
      value: item.address,
      firstDropOff: this.props.first,
      id: this.state.id,
    })
  );

  
  this.props.dispatch(
    Edit_Travel_set_Dropoff_inputs({
      name: "adresss_loc",
      value: { lng: item.coordinates[0], lat: item.coordinates[1] },
      firstDropOff: this.props.first,
      id: this.state.id,
    })
  );

 
    this.props.dispatch(
      Set_latlng({
        lng: item.coordinates[0],
        lat: item.coordinates[1],
      })
    );

  }

  GetserachListItemOnclick = () => {
    if (_.isEmpty(this.props.address)) {
      return this.handleRecentList
    } else {
      return this.handleList
    }
  };

  GetSearchListError = () => {

    //no recent address
    if (this.props.recentAddress?.length == 0 && _.isEmpty(this.props.address)) {
      return {
        errorDescription: this.props.t("NoRecentAddressD"),
        errorTitle: this.props.t("NoRecentAddress"),
        errorIcon: HISTORY,
      }
    }

     //CustomerIsNotChoosed


     if ((!this.props.suggestListPickUpDropOffLoading &&
       this.props.suggestPickUpDropOffListData.length === 0 && !_.isEmpty(this.props.address)) || (!_.isEmpty(this.props.address) && !this.props.suggestListPickUpDropOffLoading && !this.props.suggestPickUpDropOffListData)) {
       return {
         errorDescription: this.props.t("NoResultfromLocalDatabaseD"),
         errorTitle: this.props.t(this.state.localSearchIsActive ? "NoResultfromLocalDatabase" : "NoResultfromMapService"),
         errorIcon: null,
       }
     }

   };



   GetserachListItem = () => {

    if (_.isEmpty(this.props.address)) {
      return RecentAddressItem
    } else {
      return AddressItem
    }


     }


     serachListData = () => {

      if (_.isEmpty(this.props.address)) {

       if (_.isEmpty(this.props.recentAddress)) {
         return null //error recentAddress
       } else {
         return this.props.recentAddress
       }

     } else {
       if (_.isEmpty(this.props.suggestPickUpDropOffListData)) {
         return null //error suggestPickUpDropOffListData
       } else {
         return this.props.suggestPickUpDropOffListData
       }
     }


    }


    handleShowInfo = () => {

  
      const myEvent = new CustomEvent("closeSliders", { detail: null });
      document.dispatchEvent(myEvent);
  
  
      this.handleCollapse()
  
    }


    
  openAddressbook = () => {

    const myEvent = new CustomEvent("closeSliders", { detail: null });
    document.dispatchEvent(myEvent);


    setTimeout(() => {
      if (this.props.openAddressBook && this.props.addressBookSource) {
        this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));
      } else {
        this.props.dispatch(
          SetopenAddressBookInNewOrder(
            true,
            "dropoff",
            this.props.id,
            "AddressbookDropOf",
            this.props.first
          )
        );
      }
 }, 100);
  }


  render() {
    const { t } = this.props;

    if (isEmpty(String(this.props.adresss_loc.lat))) {
      return <div className={styles.PickupOnlyNewOrderContainer} style={{width:"518px",borderBottom:(this.props.dropOffsCount > 1 || this.props.orderType) && "1px solid rgba(244, 244, 247, 1)"}} >

        <div className={styles.PickupOnlyNewOrderContainerFirstRow}>
          <IconProvider Icon={DROPOFF} size='16' />
          <Typography text={t("DropOff")} weight='Body_Middle_Bold' />
        </div>

        <div className={styles.PickupOnlyNewOrderContainerSecondRow}>
          <SearchList onChange={(e) => this.handleDropOffInputs(e, "address")} style={{ zIndex: "2" }} value={this.props.address} placeHolder={t("SearchAddress")} isloading={this.props.suggestListPickUpDropOffLoading} listItemOnclick={this.GetserachListItemOnclick()} error={this.GetSearchListError()} errorDescription={this.GetSearchListError()?.errorDescription} errorTitle={this.GetSearchListError()?.errorTitle} errorIcon={this.GetSearchListError()?.errorIcon}
            ListItemChildren={this.GetserachListItem()} list={this.serachListData()} inputInnerChildren={this.state.isLocalAddressActive ? (<DropDown size='Small' placement='bottomEnd' text={this.state.localSearchIsActive ? t("Local") : t("Map")} data={[t("Map"), t("Local")]} onClick={this.handleChangeLocalSearch} />) : null}
          />
          <Button icon={LOCATION} size='Medium' type='Secondary' style={{ marginInlineEnd: "7px" }} onClick={this.handleCollapse} isActive={this.props.activePickupDropOff.id === this.state.id} />
          <Button icon={ADDRESS_BOOK} size='Medium' type='Secondary' disabled={(String(this.props.customerId).length > 0) ? false : true} onClick={this.openAddressbook} isActive={this.props.addressBookSource} />
          {this.props.dropOffsCount > 1 && <Button size={'Small'} icon={CLOSE_DARK} type='Secondary' style={{ marginInlineStart: "4px", backgroundColor: "transparent", padding: "8px 8px" }} onClick={this.removeDropOff} />}

        </div>
      </div>
    } else {

      return (<div className={styles.pickupContainer} style={{width:"518px",borderBottom: (this.props.dropOffsCount > 1 || this.props.orderType) && "1px solid rgba(244, 244, 247, 1)"}}>
        {this.props.dropOffsCount > 1 && <img src={drag_drop} className="drag_drop_cursor" style={{ marginTop: "1px" }} />}
        {this.props.dropOffsCount > 1  ? <span className={styles.numtag}> {this.props.index + 1} </span> : <img src={DROPOFF} />}
        <Typography weight="Body_Small_Regular" text={this.props.address} style={{marginInlineEnd:"auto"}} />

        <Button icon={INFO_CIRCLE} size='Medium' type='Secondary' badge={false} onClick={this.handleShowInfo} isActive={this.props.activePickupDropOff.id === this.state.id} style={{marginInlineEnd:"12px"}} />
        <Button size={'Small'} icon={CLOSE_DARK} type='Secondary' style={{ marginInlineStart: "4px", backgroundColor: "transparent", padding: "8px 8px" }} onClick={this.resetPickup} />
        {this.props.activePickupDropOff.id === this.state.id && <DropoffInfoEditOrder handleShowInfo={this.handleShowInfo} {...this.props} />}
      </div>)
    }

    

    return (
      <div
        className={`dropOffCustomized EditAddresspasserngerContainer ${this.props.last && "LastdropOffCustomized"
          } ${styles.passerngerContainer}`}
      >

        <div className={styles.frow}>
             <pickuptitle
          className="EditAddressPickuptitle"
          onClick={this.handleCollapse}
          style={{maxWidth:"321px"}}
        >
          <span> {this.props.dropOffsCount > 1 && <img src={drag_drop} className="drag_drop_cursor" style={{ marginTop: "1px" }} />}
            <Trans i18nKey={"DropOff"}> </Trans> {this.props.number} </span>
          <span
            className={"miniAddressdrop EditAddressminiAddressdrop"}
          >
            {this.props.activePickupDropOff.id !== this.state.id &&
              this.props.address}
          </span>
        </pickuptitle>
        <span className="reservDetail EditAddressReservDetail" style={{margin:"0px",marginInlineEnd:"auto",marginInlineStart:"5px"}}>
          {" "}
          {this.props.activePickupDropOff.id !== this.state.id &&
            ((momentJalaali(this.props.scheduleDateBefore).isValid() && this.props.scheduleDateBefore > 0)
              ? momentJalaali(this.props.scheduleDateBefore).format(
                get_lang() == "fa" ? "HH:mm jMM/jDD" : "MM/DD HH:mm"
              )
              : (momentJalaali(this.props.scheduleDateAfter).isValid() && this.props.scheduleDateAfter > 0)
                ? momentJalaali(this.props.scheduleDateAfter).format(
                  get_lang() == "fa" ? "HH:mm jMM/jDD" : "MM/DD HH:mm"
                )
                : null)}
        </span>
        {!this.props.first && <img
          onClick={this.removeDropOff}
          src={trash}
          className={`trashDrop ${this.state.id === "dropoff" && "displayNone"
            }`}
          style={{ marginTop: "2px" , marginInlineStart :"0px" ,marginInlineEnd:"0px" }}
        />}
        <img
          onClick={this.handleCollapse}
          src={arrow}
          className={`pickupCollIconVR pickupCollIconEADDRESS ${this.props.activePickupDropOff.id === this.state.id && "rotate180"
            }`}
        />

        </div>
     

    

        <Collapse
          className="pickupcollapsecontainer yodmvydj"
          in={this.props.activePickupDropOff.id === this.state.id}
          timeout="auto"
          unmountOnExit
          style={{ border: "0px", paddingTop: "37px" }}
        >

<ClickAwayListener onClickAway={()=>{
            this.setState({
              showpoper: false
            });
          }}>
            <div className={`NewOrderPickupContainer_DRDO ${(this.state.showpoper && this.props.adresss_loc.lat == "") && "DriverManualSelectContainer_DRDO_Active"}`} style={{ zIndex: "12px", margin: "0px", left: "12px", top: "55px", width: "483px" }}>
              <div className="pickupDropOffInputResetContainer">
                {this.state.isLocalAddressActive && <img src={this.state.localSearchIsActive ? address_database : MapSearch} onClick={this.handleChangeLocalSearch} className={"localSearchIsActiveicon"} />}

                <input
                  autoFocus="autofocus"
                  value={this.props.address}
                  onChange={(e) => this.handleDropOffInputs(e, "address")}
                  autocomplete="93658369"

                  ref={this.anchorEl}
                  onClick={(e)=>{
                    this.setState({
                      showpoper: true,
                      cursor : e.target.selectionStart
                    });
                  }}
                  placeholder={t(this.state.localSearchIsActive ? "SearchinLocalDatabase" : "SearchinMapService")}
                />



                {this.props.adresss_loc.lat !== "" && (
                  <img
                    src={closeCircle}
                    onClick={this.resetPickup}
                    style={{ margin: "0  8.6px" }}
                  />
                )}


              </div>

              {this.props.adresss_loc.lat == "" && <React.Fragment>

                {(this.state.showpoper && !isEmpty(this.props.address) && this.props.suggestPickUpDropOffListData.length > 0 &&
                  !this.props.suggestListPickUpDropOffLoading) &&
                  <div className="pickupDropOffRecentAddressItemListContainer">
                    {this.props.suggestPickUpDropOffListData.map((item) => {
                      return <div className="pickupDropOffRecentAddressItemList" onClick={() => {
                        this.handleList(item);
                      }}>
                        <img src={iconIcPin} />
                        <span> {item.address}</span>
                        <p> {isEmpty(item.secondaryAddress) ? "----" : item.secondaryAddress} </p>
                      </div>
                    })}
                  </div>
                }



                {(this.state.showpoper && this.props.suggestListPickUpDropOffLoading && !isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddress">
                    <img src={iconIcPin} />
                    <p className="NoRecentAddress"><Trans i18nKey={"poperLoading"}> </Trans></p>
                    <p className="NoRecentAddressD"><Trans i18nKey={"fetchingdata"}> </Trans></p>
                  </div>
                }


                {(this.state.showpoper && isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddress">
                    <img src={iconIcPin} />
                    <p className="NoRecentAddress"><Trans i18nKey={"Noinformationfound"}> </Trans></p>
                    <p className="NoRecentAddressD"><Trans i18nKey={"Pleasetryanotherkeyword"}> </Trans></p>
                  </div>
                }



                {(this.state.showpoper && !this.props.suggestListPickUpDropOffLoading &&
                  this.props.suggestPickUpDropOffListData.length === 0 && !isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddress">
                    <img src={iconIcPin} />
                    <p className="NoRecentAddress"><Trans i18nKey={this.state.localSearchIsActive ? "NoResultfromLocalDatabase" : "NoResultfromMapService"}> </Trans></p>
                    <p className="NoRecentAddressD"><Trans i18nKey={"NoResultfromLocalDatabaseD"}> </Trans></p>
                  </div>
                }



              </React.Fragment>}






            </div>

          </ClickAwayListener>

     

          {(this.props.activeVehicleType && this.props.activeVehicleType.setting.isDropoffAddressRoomFloorBlockActive) && <>

            <input
              value={this.props.block}
              onChange={this.handleDropOffInputs}
              id={"block"}
              name={"62fd670b" + this.props.number}
              autocomplete={"62fd670b" + this.props.number}
              className="blockPickup"
              placeholder={t("block")}
            />
            <input
              value={this.props.floor}
              onChange={this.handleDropOffInputs}
              id={"floor"}
              name={"be4c90f8" + this.props.number}
              autocomplete={"be4c90f8" + this.props.number}
              className="floorPickup"
              placeholder={t("Floor")}
            />
            <input
              value={this.props.unit}
              onChange={this.handleDropOffInputs}
              id={"unit"}
              name={"59ee84aa" + this.props.number}
              autocomplete={"59ee84aa" + this.props.number}
              className="UnitPickup"
              placeholder={t("Unit")}
            />

          </>}

          <div className="fourInputsContainer">
            {(this.props.activeVehicleType && this.props.activeVehicleType.setting.isDropoffAddressNameActive) && <div className="inputWithRequired">

              <input
                value={this.props.recieverFullName}
                onChange={this.handleDropOffInputs}
                id={"recieverFullName"}
                name={"eb89c0d4+this.props.number"}
                autocomplete={"eb89c0d4" + this.props.number}
                className="senderfullnamePickup"
                placeholder={t("Recieverfullname")}
              />

              {(this.props.activeVehicleType.setting.isDropoffAddressNameRequired) && <span className="inputRequired">{this.props.t("Required")}</span>
              }
            </div>}

            {(this.props.activeVehicleType && this.props.activeVehicleType && this.props.activeVehicleType.setting && this.props.activeVehicleType.setting.isDropoffAddressPhoneActive) &&
              <div className="inputWithRequired" >
                <PhoneInput
                  defaultCountry={global.config.Country}
                  value={this.props.recieverPhoneNumber}
                  onChange={(eee) => this.handlePhoneInput(eee, "recieverPhoneNumber")}
                  id={"recieverPhoneNumber"}
                  name={"bbddca44" + this.props.number}
                  autocomplete={"bbddca44" + this.props.number}
                  className="senderphonenumberPickup"
                  placeholder={t("Recieverphonenumber")}
                />


                {(this.props.activeVehicleType && this.props.activeVehicleType.setting && this.props.activeVehicleType.setting.isDropoffAddressPhoneRequired) && <span className="inputRequired">{this.props.t("Required")}</span>
                }
              </div>}

            {(this.props.activeVehicleType && this.props.activeVehicleType && this.props.activeVehicleType.setting && this.props.activeVehicleType.setting.isDropoffAddressEmailActive) && <div className="inputWithRequired"
              style={{ border: this.props.email.length > 0 ? this.validate(this.props.email) ? "solid 1px rgba(128, 128, 128, 0.2)" : "1px solid red" : "solid 1px rgba(128, 128, 128, 0.2)" }}
            >
              <input
                value={this.props.email}
                onChange={this.handleDropOffInputs}
                id={"email"}
                name={"420da2de0" + this.props.number}
                autocomplete={"420da2de0" + this.props.number}
                className="notesfordriverPickup"
                placeholder={t("email")}
              />
              {(this.props.activeVehicleType && this.props.activeVehicleType.setting && this.props.activeVehicleType.setting.isDropoffAddressEmailRequired) && <span className="inputRequired">{this.props.t("Required")}</span>
              }
            </div>}
            <div className="inputWithRequired" >

              <input
                value={this.props.noteForDriver}
                onChange={this.handleDropOffInputs}
                id={"noteForDriver"}
                name={"420d2de0" + this.props.number}
                autocomplete={"420d2de0" + this.props.number}
                className="notesfordriverPickup"
                placeholder={t("notesfordriver")}
              />

            </div>
          </div>

          <ServiceCustomHook serviceId={this.props.currentService}>
            <Schedule
              forceShow
              firstDropOff={this.props.first}
              id={this.state.id}
              scheduleDateBefore={this.props.scheduleDateBefore}
              scheduleDateAfter={this.props.scheduleDateAfter}
            />
          </ServiceCustomHook>


        </Collapse>

      
        <BackdropCustom open={this.state.showpoperSaved}>
          <Popper
            style={{
              width: "475px",
              zIndex: "11",
              marginTop: "0px",
              maxHeight: "150x",
              left: "25px !important",
            }}
            placement={"bottom-end"}
            id={"passenger"}
            open={this.state.showpoperSaved}
            anchorEl={this.anchorEl.current}
            className="left25"
          >
            <ClickAwayListener onClickAway={this.handleshowpoperDrop}>
              <Paper
                className="xxx"
                style={{
                  width: "476px",
                  float: "right",
                  borderRadius: "8px",
                  paddingTop: "5px",
                  marginTop: "10px",
                  right: "271px",
                  maxHeight: "201px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                elevation={3}
              >
                <input
                  value={this.state.searchAddrees}
                  onChange={this.handleSavedDropInput}
                  className="savedAddressSearch"
                  placeholder={this.props.t("Search")}
                />
                <div
                  style={{
                    maxHeight: "111px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {this.props.customerId ? this.props.favAddresss.length === 0 &&
                    this.props.customerId.length > 0 && (
                      <div>
                        <span className="NotravelsListText">
                          <Trans i18nKey={"Noaddressavailable"}> </Trans>
                        </span>

                        <div
                          onClick={this.hanldeOpenNewFav}
                          className="divManagefavoriteaddresses"
                          style={{ marginTop: "10px" }}
                        >
                          <span className="Managefavoriteaddresses">
                            <Trans i18nKey={"Managefavoriteaddresses"}> </Trans>
                          </span>
                        </div>
                      </div>
                    ) : null}

                  {this.props.favAddresss ? this.props.favAddresss.length > 0 &&
                    this.props.favAddresss
                      .filter((item) => {
                        return this.state.searchAddrees.length === 0
                          ? true
                          : item.title.includes(this.state.searchAddrees);
                      })
                      .map((item) => {
                        return (
                          <favAddressselectorInList
                            onClick={() => {
                              this.handleListFavAddress(item);
                            }}
                          >
                            <name> {item.title}</name>
                            <addres> {item.address}</addres>
                          </favAddressselectorInList>
                        );
                      }) : null}
                </div>
                {this.props.customerId ? this.props.favAddresss.length !== 0 &&
                  this.props.customerId.length > 0 && (
                    <div
                      className="divManagefavoriteaddresses"
                      onClick={this.hanldeOpenNewFav}
                    >
                      <span className="Managefavoriteaddresses">
                        <Trans i18nKey={"Managefavoriteaddresses"}> </Trans>
                      </span>
                    </div>
                  ) : null}
              </Paper>
            </ClickAwayListener>
          </Popper>
        </BackdropCustom>
      </div>
    );
  }
}

const mapStateToProps = (state ,ownProps) => ({
  favAddresss: state.FavAddress.list,
  customerid: state.FavAddress.customerId,
  mapRef: state.Map.mapRef,
  center: state.Map.center,

  activePickupDropOff: state.EditOrder.editOrder.activePickupDropOff,
  list: state.EditOrder.editOrder.dropOffs.list,
  loading: state.EditOrder.editOrder.dropOffs.loading,
  center: state.Map.center,
  suggestListPickUpDropOffLoading:
    state.EditOrder.editOrder.suggestListPickUpDropOffLoading,
  suggestListPickUpDropOffOrdered:
    state.EditOrder.editOrder.suggestListPickUpDropOffOrdered,
  suggestPickUpDropOffListData:
    state.EditOrder.editOrder.suggestPickUpDropOffListData,
    openAddressBook: state.Addressbook.openAddressBookInNewOrder,
    addressBookSource: state.Addressbook.openAddressBookInNewOrder
    ? state.Addressbook.sourceOfAddressBookInNewOrder
      ? state.Addressbook.sourceOfAddressBookInNewOrder.type == "dropoff" &&
      ownProps.Sid == state.Addressbook.sourceOfAddressBookInNewOrder.id
      : false
    : false,
  isScheduled: state.ViewTravel.pureData ? state.ViewTravel.pureData.isScheduled : false,
  activeVehicleType: state.EditOrder.editOrder.service ? (state.EditOrder.editOrder.service.activeVehicleType ? state.EditOrder.editOrder.service.activeVehicleType : null) : null,
  currentService: state.EditOrder.editOrder.service ? (state.EditOrder.editOrder.service.activeVehicleType ? state.EditOrder.editOrder.service.activeVehicleType.id : null) : null,
  ActivescheduleSetting: state.EditOrder.editOrder.service.activeVehicleType ? (state.EditOrder.editOrder.service.activeVehicleType.scheduleSetting ? state.EditOrder.editOrder.service.activeVehicleType.scheduleSetting.isActive : false) : false,
  recentAddress: state.EditOrder.editOrder.customer.recentAddreess ,
  orderType: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.type
    ? state.ViewTravel.pureData.type
    : null
  : null,


});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));



function ServiceCustomHook(props) {
  const service = useLocalServices(props.serviceId);

  return (
    <>
      {(service && service.scheduleSetting.isActive) &&
        props.children
      }
    </>
  );
}